import { defineStore } from 'pinia'
import { computed } from 'vue'
import { auth } from '@/firebase'
import { collection, doc } from 'firebase/firestore'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
} from 'firebase/auth'
import type { AuthError } from 'firebase/auth'
import { db } from '@/firebase'
import type { UserConfig } from '../../functions/src/types'
import { useAppStore } from '@/stores/app'
import { useCurrentUser, useDocument } from 'vuefire'

export const useUserStore = defineStore('userStore', () => {
  const currentUser = useCurrentUser()
  const appStore = useAppStore()
  const { incrementLoadingQueue, decrementLoadingQueue } = appStore

  const userConfigSource = computed(() => {
    return currentUser.value?.uid
      ? doc(collection(db, 'users'), currentUser.value?.uid)
      : null
  })
  const { data: userConfig, pending: userConfigPending } =
    useDocument<UserConfig>(userConfigSource)

  // async function fetchUser() {
  //   return new Promise((resolve, reject) => {
  //     incrementLoadingQueue()
  //     const unsubscribe = onAuthStateChanged(
  //       auth,
  //       async (signedInUser) => {
  //         unsubscribe()
  //         if (signedInUser) {
  //           user.value = signedInUser
  //         } else {
  //           user.value = null
  //         }
  //         decrementLoadingQueue()
  //         resolve(user)
  //       },
  //       reject
  //     )
  //   })
  // }

  // Veify is only used in this file currently
  async function verify() {
    try {
      if (currentUser.value) {
        await sendEmailVerification(currentUser.value, {
          url: `${window.location.origin}/login/?email=${currentUser.value.email}`,
        })
      } else {
        throw new Error('Cannot verify, no user')
      }
    } catch (err) {
      console.error(err)
    }
  }

  async function reset(email: string) {
    incrementLoadingQueue()
    try {
      if (email) {
        await sendPasswordResetEmail(auth, email, {
          url: `${window.location.origin}/login/?email=${email}`,
        })
      } else {
        throw new Error('Indtast e-mail')
      }
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      decrementLoadingQueue()
    }
  }

  async function register(
    displayName: string,
    email: string,
    password: string
  ) {
    incrementLoadingQueue()
    try {
      // create user, throw it if it fails
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )
      if (response && response.user) {
        // after successful creation, send verify-email + update profile display name
        // if this fails, we dont stop the user flow, we only console.error
        try {
          await verify()
          await updateProfile(response.user, {
            displayName,
          })
        } catch (err) {
          console.error(err)
        }
      } else {
        throw new Error('No profile returned when creating user')
      }
    } catch (err) {
      decrementLoadingQueue()
      throw err
    }
    decrementLoadingQueue()
  }

  async function login(email: string, password: string) {
    incrementLoadingQueue()
    try {
      const response = await signInWithEmailAndPassword(auth, email, password)
      if (!(response && response.user)) {
        throw new Error()
      }
    } catch (err) {
      decrementLoadingQueue()
      const authError = err as AuthError
      switch (authError.code) {
        case 'auth/invalid-email':
          throw new Error('Forkert emailadresse')
        case 'auth/user-not-found':
          throw new Error('Bruger ikke fundet')
        case 'auth/wrong-password':
          throw new Error('Forkert adgangskode')
        case 'auth/user-disabled':
          throw new Error('Bruger er deaktiveret')
        default:
          throw new Error('Noget gik galt')
      }
    }
    decrementLoadingQueue()
  }

  async function logout() {
    incrementLoadingQueue()
    await signOut(auth)
    decrementLoadingQueue()
  }

  return {
    register,
    reset,
    login,
    logout,
    userConfig,
    userConfigPending,
  }
})
