<script setup lang="ts">
import { ref, watch } from 'vue'
import { RouterView, useRouter } from 'vue-router'
import { CheckIcon } from '@heroicons/vue/24/solid'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/stores/app'
import NavLayout from '@/components/NavLayout.vue'
import AppFooter from './components/AppFooter.vue'
import SpinnerModal from '@/components/SpinnerModal.vue'
const appStore = useAppStore()

const { isLoading } = storeToRefs(appStore)
</script>

<template>
  <NavLayout>
    <RouterView />
    <AppFooter class="absolute bottom-0" />
  </NavLayout>
  <SpinnerModal :show="isLoading" :show-leave="false" :duration-leave="300" />

  <vue-cookie-accept-decline :debug="false" :disableDecline="true" :showPostponeButton="false" elementId="cookie-banner"
    position="bottom" ref="cookie-banner" transitionName="slideFromBottom" type="bar">
    <template #message>
      Denne webside bruger cookies. Ved at benytte Meddi, accepterer du Meddis
      <a class="text-sky-700 font-bold hover:underline" href="/Cookie- og privatlivspolitik hos Meddi ApS.pdf"
        target="_blank">
        cookie- og privatlivspolitik
      </a>
    </template>
    <template #acceptContent>
      <span class="flex items-center justify-center">
        <CheckIcon class="h-5 w-5 mr-1.5" aria-hidden="true" />
        Forstået
      </span>
    </template>
  </vue-cookie-accept-decline>
</template>

<style>
.cookie__bar__buttons__button {
  border-radius: 6px;
  font-weight: 500;
}
</style>
