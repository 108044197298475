import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useAppStore = defineStore('appStore', () => {
  const loadingQueue = ref(0)

  const isLoading = computed(() => loadingQueue.value > 0)

  const incrementLoadingQueue = () => {
    loadingQueue.value++
  }

  const decrementLoadingQueue = () => {
    loadingQueue.value--
  }

  const withLoading = async <T>(p: Promise<T>) => {
    const store = useAppStore()
    store.incrementLoadingQueue()
    await p
    store.decrementLoadingQueue()
    return p
  }

  return {
    loadingQueue,
    isLoading,
    incrementLoadingQueue,
    decrementLoadingQueue,
    withLoading,
  }
})
