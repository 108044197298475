<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    show: boolean
    durationEnter?: number
    durationLeave?: number
    showLeave?: boolean
  }>(),
  { show: false, durationEnter: 200, durationLeave: 200, showLeave: true }
)

const duration = computed(() => ({
  enter: props.durationEnter,
  leave: props.durationLeave,
}))
</script>

<template>
  <Transition
    v-if="showLeave || show"
    :duration="duration"
    enter-active-class="ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="show"
      aria-label="Loading..."
      role="status"
      class="z-60 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity flex justify-center items-center"
    >
      <svg class="h-12 w-12 animate-spin stroke-sky-900" viewBox="0 0 256 256">
        <line
          x1="128"
          y1="32"
          x2="128"
          y2="64"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="195.9"
          y1="60.1"
          x2="173.3"
          y2="82.7"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="224"
          y1="128"
          x2="192"
          y2="128"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="195.9"
          y1="195.9"
          x2="173.3"
          y2="173.3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="128"
          y1="224"
          x2="128"
          y2="192"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="60.1"
          y1="195.9"
          x2="82.7"
          y2="173.3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="32"
          y1="128"
          x2="64"
          y2="128"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="60.1"
          y1="60.1"
          x2="82.7"
          y2="82.7"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  </Transition>
</template>

<style scoped></style>
