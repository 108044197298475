import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions, httpsCallable } from 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyCtA3guNP1PWGuZPUgZvdD3g19rXuh6CLY',
  authDomain: 'meddi-services.firebaseapp.com',
  projectId: 'meddi-services',
  storageBucket: 'meddi-services.appspot.com',
  messagingSenderId: '551995848244',
  appId: '1:551995848244:web:fbe9a2465b25407d20fc61',
}

const firebaseApp = initializeApp(firebaseConfig)

const auth = getAuth(firebaseApp)
const analytics = getAnalytics(firebaseApp)
const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)
const functions = getFunctions(firebaseApp)
const functionsEuropeWest3 = getFunctions(firebaseApp, 'europe-west3')

// Emulators
// import { connectFirestoreEmulator } from 'firebase/firestore'
// import { connectStorageEmulator } from 'firebase/storage'
// import { connectFunctionsEmulator } from 'firebase/functions'
// connectFirestoreEmulator(db, 'localhost', 8080)
// connectStorageEmulator(storage, 'localhost', 9199)
// connectFunctionsEmulator(functions, 'localhost', 5001)

export {
  firebaseApp,
  auth,
  analytics,
  db,
  storage,
  functions,
  functionsEuropeWest3,
  httpsCallable,
}
