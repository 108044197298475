import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'

import { createPinia } from 'pinia'
import { VueFire, VueFireAuth } from 'vuefire'
import Toast from 'vue-toastification'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'

import App from './App.vue'
import router from './router'
import { firebaseApp } from './firebase'

import 'vue-toastification/dist/index.css'
import './style.css'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://678c93800270fc97f1e3d3914b9e45e0@o4506914622799872.ingest.us.sentry.io/4506914626011136',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  tracePropagationTargets: [],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // set the environment (development/production)
  environment: import.meta.env.MODE,
})

// VueFire
app.use(VueFire, {
  firebaseApp,
  modules: [VueFireAuth()],
})

// Pinia state
app.use(createPinia())

// Router
app.use(router)

// vue-toastification
app.use(Toast, {})

// vue-cookie-accept-decline
app.component('vue-cookie-accept-decline', VueCookieAcceptDecline)

app.mount('#app')
